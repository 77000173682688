/*
    GEL Spacing
    Assumes 16px is set as the default font-size.
    This is changeable in the user's browser settings, as the html font-size is 100%
*/
/** 4px */
export const GEL_SPACING_HLF = `0.25rem`;
/** 8px */
export const GEL_SPACING = `0.5rem`;
/** 12px */
export const GEL_SPACING_HLF_TRPL = '0.75rem';
/** 16px */
export const GEL_SPACING_DBL = `1rem`;
/** 24px */
export const GEL_SPACING_TRPL = `1.5rem`;
/** 32px */
export const GEL_SPACING_QUAD = `2rem`;
/** 40px */
export const GEL_SPACING_QUIN = `2.5rem`;
/** 48px */
export const GEL_SPACING_SEXT = `3rem`;
/** 56px */
export const GEL_SPACING_SEPT = `3.5rem`;

/*
    GEL Grid
    Margins and Gutters are defined here
    https://www.bbc.co.uk/gel/guidelines/grid#grid-sizes
*/
export const GEL_MARGIN_BELOW_400PX = `${GEL_SPACING}`;
export const GEL_GUTTER_BELOW_600PX = `${GEL_SPACING}`;
export const GEL_MARGIN_ABOVE_400PX = `${GEL_SPACING_DBL}`;
export const GEL_GUTTER_ABOVE_600PX = `${GEL_SPACING_DBL}`;
